import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@shared/services/auth.service';
import { SEOService } from '@shared/services/seo.service';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, map, mergeMap, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, HeaderComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit {
  public title = 'Rally Starter';
  public description: string =
    'Rally Starter is a digital advocacy platform to mobilize communities and build movements that benefit humanity. \
  Create your campaign and influence change now!';
  public image: string =
    'https://firebasestorage.googleapis.com/v0/b/rally-starter-production.appspot.com/o/uploads%2Fpages%2Frally-starter-preview.jpg?alt=media&token=6c63e3df-858a-4907-bacd-128d5eace064';
  public inIframe: boolean = false;
  public isAdminPage: boolean = false;

  private dialog: DialogService = inject(DialogService);

  public constructor(
    private router: Router,
    private metaService: Meta,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private authService: AuthService
  ) {
    this.seoService.updateTitle(this.title);
    this.metaService.addTags([
      { name: 'msapplication-TileColor', content: '#FFFFFF' },
      { name: 'msapplication-TileImage', content: 'https://rallystarter.com/assets/img/mstile-150x150.png' },
      { name: 'google-site-verification', content: 'biwcL5BR8a7nFeUhvwogYvjxDPxr0MX0zHmfX2nqZfM' },
      { name: 'description', content: this.description },
      { property: 'fb:app_id', content: '632633922205118' },
      { property: 'og:title', content: 'Rally Starter' },
      { property: 'og:image', content: this.image },
      { property: 'og:url', content: 'https://rallystarter.com' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'Rally Starter' },
      { property: 'og:description', content: this.description },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:description', content: this.description },
      { name: 'twitter:site', content: '@rallystarters' },
      { name: 'twitter:title', content: 'Rally Starter' },
      { name: 'twitter:image', content: this.image },
      { name: 'twitter:card', content: 'summary_large_image' }
    ]);

    this.router.events
      .pipe(
        tap(() => {
          this.isAdminPage = this.router.url.includes('/admin');
          this.dialog.dialogComponentRefMap.forEach((dialog) => {
            dialog.destroy();
          });
        }),
        filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap(async (route) => route.snapshot.data)
      )
      .subscribe((event) => {
        this.seoService.updateTitle(event.title || this.title);
        this.seoService.updateDescription(event.description || this.description);
        this.seoService.updateURL(event.url || this.router.url);
        this.seoService.updateImage(event.image || this.image);
      });
  }

  public ngOnInit(): void {
    this.injectHotjar();
    this.authService.algoliaKey().pipe(take(1)).subscribe();
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.inIframe = params.rallyEmbed === 'true';

      if (this.inIframe) {
        const intervalId = setInterval(() => {
          const smartsupp = document.getElementById('smartsupp-widget-container');
          if (smartsupp) {
            smartsupp.remove();
            clearInterval(intervalId);
          }
        }, 100);
      }
    });
  }

  private injectHotjar(): void {
    // Initialize Hotjar
    if (environment.env === 'prod') {
      const script = document.createElement('script');
      script.innerHTML = `(function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: 1901652, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`;
      document.head.appendChild(script);
    }
  }
}
